import axios from 'axios'

console.log('test autodetect api');
console.log('location:'+location);
console.log('host:'+location.host);

// Configuración por defecto del objeto axios
// a que api nos conectamos para obtener datos
let auxurl = '';
switch(location.host){
    case 'localhost:8081':
        auxurl = 'http://localhost:8081/';
        break;
    case 'sistema.dicett.com.ar':
        auxurl = 'https://apidicett.lambdasi.com.ar/api/';
        break;
    case 'sgc.lambdasi.com.ar':
        //auxurl = 'https://sgcapi.lambdasi.com.ar/api/';
        auxurl = 'https://lambda.apilsix.com.ar/api/';
        break;
    case 'sgcweb.lsix.com.ar':
        auxurl = 'https://test.apilsix.com.ar/api/';
        break;
    case 'witralen.misgc.com.ar':
        auxurl = 'https://witralen.apilsix.com.ar/api/';
        break;
    default:
        alert('No se encontró configuración para la API');
}
//auxurl = 'https://testapi.lambdasi.com.ar/api/';
const url = auxurl;


const instance = axios.create({
    baseURL: url
})

instance.interceptors.request.use(
    (config) => {
        var token = localStorage.access_token
        if (token) {
            config.headers['Authorization'] = 'Bearer ' + token
        }
        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

export default instance