import Vue from 'vue'
import Router from 'vue-router'
import userservice from './services/UserServices'

Vue.use(Router)

const router = new Router({
    base: process.env.BASE_URL,
    routes: [{
            path: '*',
            name: '*',
            component: () =>
                import ('./views/Error/NotFound.vue'),
            meta: { Error: true }
        },
        {
            path: '/404',
            name: 'NotFound',
            component: () =>
                import ('./views/Error/NotFound.vue'),
            meta: { Error: true }
        },
        {
            path: '/',
            name: 'Root',
            redirect: {
                name: 'CtaCte'
            }
        },
        // {
        //     path: '/Home',
        //     name: 'Home',
        //     component: () =>
        //         import ('./views/Home.vue'),
        // },
        {
            path: '/Home',
            name: 'Home',
            redirect: {
                name: 'CtaCte'
            }
        },
        {
            path: '/Login',
            name: 'Login',
            component: () =>
                import ('./views/Auth/Login.vue'),
            meta: { Publico: true }
        },
        {
            path: '/Recuperar',
            name: 'Recuperar',
            component: () =>
                import ('./views/Auth/Recuperar.vue'),
            meta: { Publico: true }
        },
        {
            path: '/MiCuenta',
            name: 'MiCuenta',
            component: () =>
                import ('./views/Auth/MiCuenta.vue')
        },
        {
            path: '/CtaCte/',
            name: 'CtaCte',
            component: () =>
                import ('./views/CtaCte/index.vue')
        },
        {
            path: '/Pedidos/Consultar',
            name: 'PedidosConsultar',
            component: () =>
                import ("./views/Pedidos/index.vue")
        },
        {
            path: '/Pedidos/Ver/:idodt',
            name: 'PedidosVer',
            component: () =>
                import ("./views/Pedidos/ver.vue")
        },
        {
            path: '/Pedidos/confirmar',
            name: 'PedidoTemporal',
            component: () =>
                import ("./views/Pedidos/confirmar.vue")
        },
        {
            path: '/Pedidos/Nuevo',
            name: 'PedidosNuevo',
            component: () =>
                import ("./views/Pedidos/nuevo.vue")
        },
        {
            path: '/Articulos/',
            name: 'Articulos',
            redirect: {
                name: 'PedidosNuevo'
            }
        },
        {
            path: '/Articulos/Equivalentes/:idArt',
            name: 'ArticulosEquivalentes',
            component: () =>
                import ("./views/Articulos/ArticulosEquivalentes.vue")
        },
        {
            path: '/Facturas/misFacturas',
            name: 'MisComprobantesFiscales',
            component: () =>
                import ("./views/Facturas/misFacturas.vue")
        }
        
    ]
})

router.beforeEach((to, from, next) => {
    if (!to.meta.Publico && !userservice.autenticado()) {
        return next({ name: 'Login' })
    } else if (to.meta.Publico && userservice.autenticado()) {
        return next({ name: 'Home' })
    }
    return next()
})

export default router