const MenuServices = [
    { header: 'Apps' },
    {
        title: 'Inicio',
        group: 'apps',
        icon: 'dashboard',
        name: 'Home',
        modulo: 0,
        funcion: 0
    },
    {
        title: 'Cuenta Corriente',
        group: 'CtaCte',
        name: 'CtaCte',
        icon: 'monetization_on',
        modulo: 1,
        funcion: 1
    },
    {
        title: 'Pedidos',
        group: 'Pedidos',
        icon: 'assignment',
        modulo: 2,
        funcion: 0,
        items: [
            { 
                name: 'PedidosNuevo', 
                title: 'Nuevo', 
                modulo: 2, 
                funcion: 3 
            },
            { name: 'PedidosConsultar', title: 'Consultar', modulo: 2, funcion: 2 }
        ]
    },
    {
        title: 'Artículos',
        group: 'Articulos',
        icon: 'shopping_cart',
        name: 'Articulos',
        modulo: 3,
        funcion: 4
    }
];

// reorder MenuServices
MenuServices.forEach((item) => {
    if (item.items) {
        item.items.sort((x, y) => {
            let textA = x.title.toUpperCase();
            let textB = y.title.toUpperCase();
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });
    }
});

export default MenuServices;