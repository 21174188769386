<template>
   <v-alert v-bind:type="tipo" v-bind:value="mostrarmensaje">
      {{mensaje}}
   </v-alert>
</template>

<script>
export default {
   props: {
      tipo:{
         default: 'error'
      },
      mensaje: {
         default: ''
      }
   },
   computed: {
      mostrarmensaje() {
         if(this.mensaje.length < 2){
            return false
         }
         return true
      }
   }
}
</script>